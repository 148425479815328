@import '~antd/dist/antd.css';

body {
    background-color: #313D4D;
}

body body {
    background-color: #fff;
}

.custom-gray-color {
    color: #545454;
}

.custom-light-gray-color {
    color: rgba(60, 60, 67, 0.6);
}

.named-avatar {
    background-color: #d8d8d8;
    color: #787878;
    font-weight: bold;
}

.ant-drawer-content {
    background-color: #049296 !important;
}

.ant-drawer-body {
    padding: 0px;
}

.ant-drawer-title {
    text-align: right;
}

.ant-drawer-header {
    border: none;
    background-color: #000C17;
}

.ant-menu-light .ant-menu-item:hover {
    color: #313D4D;
}

.ant-menu-light .ant-menu-item-selected {
    color: #313D4D;
}

.ant-menu-light .ant-menu-item-selected::after {
    border-right: 3px solid #313D4D;
}

.ant-menu-light .ant-menu-item-selected a {
    color: #313D4D !important;
}

.ant-menu-light .ant-menu-item a:hover {
    color: #313D4D;
}

.ant-menu-light .ant-menu-item a {
    color: white
}

.ant-drawer-header {
    background-color: #049296;
}

.ant-menu-light .ant-menu-submenu-title {
    color: white !important
}

.ant-menu-light .ant-menu-submenu-title:hover {
    color: #313D4D !important;
}

.ant-menu-light .ant-menu-submenu-title:hover .ant-menu-submenu-arrow {
    color: #313D4D !important;

}

.ant-menu-light .ant-menu-submenu-title .ant-menu-submenu-arrow {
    color: white;
}

.ant-table-thead th {
    color: #666666 !important;
    font-size: 12px;
}

.ant-page-header{
    background-color: #f0f2f5;
    border-bottom: 0.5px solid rgb(4, 146, 150);
}