.secondary_clr_dark_gray img {
    max-width: 100%;
    width: 100%;
}

.secondary_clr_dark_gray img {
    height: auto;
    vertical-align: top
}

a {
    color: #049296;
    text-decoration: none;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

a:hover {
    color: #29cc53
}

header .navbar-dark {
    height: 100px;
    position: relative;
    z-index: 1000
}

@media (max-width:1400px) {
    header .navbar-dark {
        height: 80px
    }
}

@media (max-width:991px) {
    header .navbar-dark .navbar-collapse {
        background-color: #049296;
        display: block !important;
        height: 100vh;
        overflow-y: auto;
        padding-bottom: 123px;
        position: fixed;
        right: -100%;
        top: 75px;
        -webkit-transition: right .5s ease-in-out;
        -o-transition: right .5s ease-in-out;
        transition: right .5s ease-in-out;
        width: calc(100% - 36px)
    }
}

@media (max-width:991px) and (max-width:767px) {
    header .navbar-dark .navbar-collapse {
        padding-bottom: 115px;
        top: 66px
    }
}

@media (max-width:1400px) {
    header .navbar-dark .navbar-brand img {
        height: 50px;
        width: 200px
    }
}

@media (max-width:767px) {
    header .navbar-dark .navbar-brand img {
        height: 40px;
        width: 160px
    }
}

header .navbar-dark .navbar-nav {
    margin-left: 202px
}

@media (max-width:991px) {
    header .navbar-dark .navbar-nav {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        text-align: center
    }

    header .navbar-dark .navbar-nav:first-child {
        background-color: #313d4d
    }

    header .navbar-dark .navbar-nav li {
        border-top: 1px solid #5a6970
    }
}

header .navbar-dark .navbar-nav li.active>a {
    color: #b9ff76
}

header .navbar-dark .navbar-nav a {
    color: #fff;
    display: block;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: .38px;
    line-height: 2.25;
    padding: .5rem;
    text-decoration: none;
    text-transform: uppercase
}

@media (min-width:992px) and (max-width:1549px) {
    header .navbar-dark .navbar-nav a {
        font-size: 14px;
        line-height: 34px
    }
}

@media (min-width:992px) and (max-width:1299px) {
    header .navbar-dark .navbar-nav a {
        font-size: 14px;
        line-height: 28px
    }
}

@media (min-width:992px) and (max-width:1200px) {
    header .navbar-dark .navbar-nav a {
        font-size: 12px
    }
}

@media (max-width:991px) {
    header .navbar-dark .navbar-nav a {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        background-color: #313d4d;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        font-size: 16px;
        height: 98px;
        padding-left: 36px;
        padding-right: 36px;
        text-align: left;
        width: 100%
    }
}

@media (min-width:1550px) {
    header .navbar-dark .navbar-nav a {
        font-size: 16px
    }
}

header .navbar-dark .navbar-nav a.active,
header .navbar-dark .navbar-nav a:hover {
    color: #b9ff76
}

header .navbar-dark .navbar-nav a[href="#"] {
    cursor: context-menu
}

header .navbar-dark .navbar-nav.right-nav {
    margin-left: 110px;
    position: relative;
    z-index: 1
}

@media (max-width:1400px) {
    header .navbar-dark .navbar-nav.right-nav {
        margin-left: 65px
    }
}

@media (max-width:1200px) {
    header .navbar-dark .navbar-nav.right-nav {
        margin-left: 20px
    }
}

@media (max-width:991px) {
    header .navbar-dark .navbar-nav.right-nav {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        text-align: center
    }
}

header .navbar-dark .navbar-nav.right-nav:after {
    background-color: #049296;
    border-top-left-radius: 20px;
    content: "";
    height: 100px;
    left: -50px;
    position: absolute;
    top: -22px;
    -webkit-transform: skew(338deg);
    -ms-transform: skew(338deg);
    transform: skew(338deg);
    width: 100vw;
    z-index: -1
}

@media (max-width:1400px) {
    header .navbar-dark .navbar-nav.right-nav:after {
        height: 80px;
        left: -30px;
        top: -12px
    }
}

@media (max-width:1299px) {
    header .navbar-dark .navbar-nav.right-nav:after {
        top: -17px
    }
}

@media (max-width:1200px) {
    header .navbar-dark .navbar-nav.right-nav:after {
        left: -10px;
        top: -17px
    }
}

@media (max-width:991px) {
    header .navbar-dark .navbar-nav.right-nav:after {
        display: none
    }

    header .navbar-dark .navbar-nav.right-nav li {
        border-top: 1px solid hsla(0, 0%, 96%, .3)
    }

    header .navbar-dark .navbar-nav.right-nav li a {
        background-color: transparent
    }
}

header .navbar-dark .navbar-nav.right-nav>li:last-child a {
    height: 56px;
    margin-right: 10px;
    position: relative;
    text-align: center;
    width: 178px;
    z-index: 1
}

@media (max-width:1400px) {
    header .navbar-dark .navbar-nav.right-nav>li:last-child a {
        height: 46px;
        width: 130px
    }
}

@media (max-width:1200px) {
    header .navbar-dark .navbar-nav.right-nav>li:last-child a {
        height: 46px;
        width: 130px
    }
}

@media (max-width:991px) {
    header .navbar-dark .navbar-nav.right-nav>li:last-child a {
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        align-items: center;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        height: 56.9px;
        justify-content: center;
        line-height: 0;
        margin: 36px auto 43px;
        padding: 0;
        width: 170px
    }
}

@media (max-width:767px) {
    header .navbar-dark .navbar-nav.right-nav>li:last-child a {
        width: 178px
    }
}

@media (min-width:1300px) {
    header .navbar-dark .navbar-nav.right-nav>li:last-child a {
        height: 56px;
        width: 178px
    }
}

header .navbar-dark .navbar-nav.right-nav>li:last-child a:before {
    border: 3px solid #fff;
    border-bottom-right-radius: 15px;
    border-top-left-radius: 15px;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: -3px;
    -webkit-transform: skew(338deg);
    -ms-transform: skew(338deg);
    transform: skew(338deg);
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    width: 100%;
    z-index: -1
}

@media (max-width:1400px) {
    header .navbar-dark .navbar-nav.right-nav>li:last-child a:before {
        top: -1px
    }
}

@media (max-width:1299px) {
    header .navbar-dark .navbar-nav.right-nav>li:last-child a:before {
        top: -2
    }
}

@media (max-width:1200px) {
    header .navbar-dark .navbar-nav.right-nav>li:last-child a:before {
        top: 0
    }
}

header .navbar-dark .navbar-nav.right-nav>li:last-child a:hover {
    color: #049296
}

header .navbar-dark .navbar-nav.right-nav>li:last-child a:hover:before {
    background-color: #fff;
    border-color: #fff
}

header .navbar-dark .navbar-nav.right-nav>li:last-child.current-menu-item a {
    color: #b9ff76
}

header .navbar-dark .navbar-nav.right-nav>li:last-child.current-menu-item a:before {
    border-color: #b9ff76
}

header .navbar-dark .navbar-nav.right-nav>li:last-child.current-menu-item a:hover {
    color: #049296
}

header .navbar-dark .navbar-nav.right-nav>li:last-child.current-menu-item a:hover:before {
    background-color: #fff;
    border-color: #fff
}

header .navbar-dark .navbar-nav li {
    position: relative
}

@media (max-width:991px) {
    header .navbar-dark .navbar-nav li {
        width: 100%
    }
}

header .navbar-dark .navbar-nav li.current-menu-item>a {
    color: #b9ff76
}

header .navbar-dark .navbar-nav li:not(:last-child) {
    margin-right: 35px
}

@media (max-width:1400px) {
    header .navbar-dark .navbar-nav li:not(:last-child) {
        margin-right: 15px
    }
}

@media (max-width:1200px) {
    header .navbar-dark .navbar-nav li:not(:last-child) {
        margin-right: 5px
    }
}

@media (max-width:991px) {
    header .navbar-dark .navbar-nav li:not(:last-child) {
        margin-right: 0
    }
}

/* .container {
    max-width: 1672px
} */

@media (min-width:1700px) {
    .container {
        padding-left: 50px;
        padding-right: 50px
    }
}

@media (max-width:1699px) {
    .container {
        padding-left: 50px;
        padding-right: 50px
    }
}

@media (max-width:767px) {
    .container {
        padding-left: 36px;
        padding-right: 36px
    }
}

.navbar,
.navbar>.container,
.navbar>.container-fluid,
.navbar>.container-lg,
.navbar>.container-md,
.navbar>.container-sm,
.navbar>.container-xl,
.navbar>.container-xxl {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between
}

.navbar>.container,
.navbar>.container-fluid,
.navbar>.container-lg,
.navbar>.container-md,
.navbar>.container-sm,
.navbar>.container-xl,
.navbar>.container-xxl {
    -ms-flex-wrap: inherit;
    flex-wrap: inherit
}


.navbar-brand {
    font-size: 1.25rem;
    margin-right: 1rem;
    padding-bottom: .3125rem;
    padding-top: .3125rem;
    text-decoration: none;
    white-space: nowrap
}

.navbar-nav {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0
}

.navbar-nav .nav-link {
    padding-left: 0;
    padding-right: 0
}

.navbar-collapse {
    -ms-flex-preferred-size: 100%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    flex-basis: 100%;
    flex-grow: 1
}

.navbar-expand-lg .navbar-collapse {
    -ms-flex-preferred-size: auto;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    flex-basis: auto
}

@media (min-width:992px) {
    .navbar-expand-lg {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        justify-content: flex-start
    }

    .navbar-expand-lg .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-left: .5rem;
        padding-right: .5rem
    }

    .navbar-expand-lg .navbar-collapse {
        -ms-flex-preferred-size: auto;
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-lg .navbar-toggler,
    .navbar-expand-lg .offcanvas-header {
        display: none
    }

    .navbar-expand-lg .offcanvas {
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        background-color: transparent;
        border-left: 0;
        border-right: 0;
        bottom: 0;
        flex-grow: 1;
        position: inherit;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
        visibility: visible !important;
        z-index: 1000
    }

    .navbar-expand-lg .offcanvas-bottom,
    .navbar-expand-lg .offcanvas-top {
        border-bottom: 0;
        border-top: 0;
        height: auto
    }

    .navbar-expand-lg .offcanvas-body {
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-grow: 0;
        overflow-y: visible;
        padding: 0
    }
}

@media (max-width:1699px) {
    .site-inner .alignfull .jv-row-column-wrap {
        padding-left: 50px;
        padding-right: 50px
    }

    .site-inner .alignfull .jv-row-column-wrap .jv-row-column-wrap {
        padding-left: 0;
        padding-right: 0
    }
}

@media (max-width:1699px) and (max-width:767px) {
    .site-inner .alignfull .jv-row-column-wrap {
        padding-left: 15px;
        padding-right: 15px
    }

    .site-inner .alignfull .jv-row-column-wrap .jv-row-column-wrap {
        padding-left: 0;
        padding-right: 0
    }
}

.entry-content>.alignfull {
    margin-left: calc(-100vw / 2 + 100% / 2);
    margin-right: calc(-100vw / 2 + 100% / 2);
    max-width: 100vw;
}


.inner_banner_sec {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-height: 774px;
    position: relative;
    z-index: 1
}

@media (max-width:767px) {
    .inner_banner_sec {
        /* background-image: url(../../../public/assets/user.png); */
        background-position: 100% 100%;
        background-repeat: no-repeat;
        display: block;
        height: auto;
        min-height: 774px
    }
}

.inner_banner_sec .banner_content {
    max-width: 868px;
    padding-bottom: 50px;
    padding-top: 50px
}

@media (max-width:1366px) {
    .inner_banner_sec .banner_content {
        max-width: 765px
    }
}

@media (max-width:1300px) {
    .inner_banner_sec .banner_content {
        max-width: 630px
    }
}

@media (max-width:1024px) {
    .inner_banner_sec .banner_content {
        max-width: 550px
    }
}

@media (max-width:768px) {
    .inner_banner_sec .banner_content {
        max-width: 450px
    }
}

@media (max-width:767px) {
    .inner_banner_sec .banner_content {
        max-width: 100%;
        padding-bottom: 52px;
        padding-top: 40px;
        text-align: center
    }

    .inner_banner_sec .banner_content>* {
        text-align: left
    }
}

.inner_banner_sec .inner_banner_img {
    background-color: #fff;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: -1
}

@media (max-width:1700px) {
    .inner_banner_sec .inner_banner_img {
        -o-object-position: -5% center;
        object-position: -5% center
    }
}

@media (max-width:1600px) {
    .inner_banner_sec .inner_banner_img {
        -o-object-position: 0 center;
        object-position: 0 center
    }
}

@media (max-width:1500px) {
    .inner_banner_sec .inner_banner_img {
        -o-object-position: 30% center;
        object-position: 30% center
    }
}

@media (max-width:1400px) {
    .inner_banner_sec .inner_banner_img {
        -o-object-position: 20% center;
        object-position: 20% center
    }
}

@media (max-width:1300px) {
    .inner_banner_sec .inner_banner_img {
        -o-object-position: 30% center;
        object-position: 30% center
    }
}

@media (max-width:1080px) {
    .inner_banner_sec .inner_banner_img {
        -o-object-position: 40% center;
        object-position: 40% center
    }
}

@media (max-width:1024px) {
    .inner_banner_sec .inner_banner_img {
        -o-object-position: 45% center;
        object-position: 45% center
    }
}

@media (max-width:768px) {
    .inner_banner_sec .inner_banner_img {
        -o-object-position: 51% center;
        object-position: 51% center
    }
}

@media (max-width:767px) {
    .inner_banner_sec .inner_banner_img {
        height: 256px;
        -o-object-position: right top;
        object-position: right top;
        position: static
    }

    .inner_banner_sec .h1,
    .inner_banner_sec h1 {
        letter-spacing: -.43px;
        line-height: 44px
    }
}

.inner_banner_sec p {
    font-size: 24px;
    font-weight: 300;
    line-height: 1.5;
    margin-bottom: 60px;
    margin-top: 56px;
    max-width: 684px
}

@media (max-width:767px) {
    .inner_banner_sec p {
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 0;
        margin-top: 33px
    }
}

.inner_banner_sec p:empty {
    display: none
}

.inner_banner_sec .prime_button {
    line-height: 0;
    z-index: 1
}

@media (max-width:767px) {
    .inner_banner_sec .prime_button {
        font-size: 16px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 36px;
        min-width: 126px
    }
}

.inner_banner_sec .prime_button:before {
    z-index: -1
}

.inner_banner_sec .prime_button:hover {
    color: #fff
}

.inner_banner_sec .prime_button:hover:before {
    background-color: #049296;
    border-color: #049296
}

@media (min-width:768px) {
    .inner_banner_sec~.industry-solutions-sec:before {
        /* background-image: url(../../../public/assets/user.png); */
        background-position: -143px top;
        background-repeat: no-repeat;
        content: "";
        height: 1469px;
        position: absolute;
        right: 0;
        top: 48px;
        width: 100%;
        z-index: 1
    }
}

@media (min-width:768px) and (max-width:1200px) {
    .inner_banner_sec~.industry-solutions-sec:before {
        background-position-x: center;
        background-position-y: -50px
    }
}

@media (min-width:768px) and (max-width:1024px) {
    .inner_banner_sec~.industry-solutions-sec:before {
        background-position-x: center;
        background-position-y: -120px
    }
}

@media (min-width:768px) and (max-width:768px) {
    .inner_banner_sec~.industry-solutions-sec:before {
        background-position-x: 58%;
        background-position-y: -140px
    }
}

.banner_label {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #313d4d;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: .38px;
    margin-bottom: 5px;
    margin-left: 10px;
    min-height: 42px;
    padding-left: 17px;
    position: relative;
    text-transform: uppercase;
    z-index: 1
}

.banner_label:after {
    background-color: #049296;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 5px;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    -webkit-transform: skew(338deg);
    -ms-transform: skew(338deg);
    transform: skew(338deg);
    width: 28px;
    z-index: -1
}

@media (max-width:767px) {
    .banner_label {
        margin-bottom: 19px
    }
}

.banner_label.label_yallow:after {
    background-color: #f9ee1e
}

.banner_label.label_light_red:after {
    background-color: #fc8a70
}

.banner_label.label_green:after {
    background-color: #29cc53
}

.text_secondary_clr_dark_gray {
    color: #313d4d
}

.largest_heading {
    font-size: 88px;
    letter-spacing: -1.06px;
    line-height: 1.09;
    font-weight: 500;
}

@media (max-width: 1355px) {
    .largest_heading {
        font-size: 70px;
    }
}

@media (max-width: 992px) {
    .largest_heading {
        font-size: 53px;
    }
}

@media (max-width: 767px) {
    .largest_heading {
        letter-spacing: -.43px;
        line-height: 44px;
        font-size: 36px;
    }
}

.inner_banner_sec .prime_button {
    line-height: 0;
    z-index: 1;
}

.prime_button {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    align-items: center;
    background-color: transparent;
    border: 0;
    color: #049296;
    /* display: -webkit-inline-box;
    display: -ms-inline-flexbox; */
    display: inline-flex;
    /* font-family: Montserrat; */
    font-size: 16px;
    font-weight: 700;
    height: 56.9px;
    justify-content: center;
    letter-spacing: .38px;
    line-height: 0;
    margin-left: 15px;
    min-width: 235.5px;
    padding: 18px 32.5px 19px;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    z-index: 1
}

.prime_button:focus {
    background-color: transparent !important;
    color: #049296
}

.prime_button:before {
    border: 4px solid #049296;
    border-bottom-right-radius: 15px;
    border-top-left-radius: 15px;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: -1px;
    -webkit-transform: skew(338deg);
    -ms-transform: skew(338deg);
    transform: skew(338deg);
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    width: 100%;
    z-index: -1
}

.footer-block {
    background-color: #313d4d;
    color: #fff;
    padding: 118px 0 85px;
    position: relative;
    z-index: 1
}

@media (max-width:1200px) {
    .footer-block {
        padding: 70px 0 60px
    }
}

@media (max-width:767px) {
    .footer-block {
        /* background-image: url(images/1814ff7ed7b565101323.png); */
        background-position: 100% 100%;
        background-repeat: no-repeat;
        padding: 60px 0
    }
}

.footer-block .footer-logo {
    margin-bottom: 40px
}

@media (max-width:767px) {
    .footer-block .footer-logo {
        margin-bottom: 30px
    }

    .footer-block .footer-logo img {
        width: 197px
    }
}

.footer-block .logo-content p {
    color: #fff;
    font-size: 16px;
    letter-spacing: .13px;
    line-height: 1.5;
    margin-bottom: 25px
}

@media (max-width:767px) {
    .footer-block .logo-content p {
        margin-bottom: 27px
    }
}

.footer-block .logo-content p span {
    display: block
}

.footer-block .logo-content p a {
    color: #fff;
    text-decoration: none
}

.footer-block .logo-content p a:hover {
    color: #29cc53
}

.footer-block .footer-icons ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    margin: 0;
    padding: 0
}

.footer-block .footer-icons ul li {
    list-style: none;
    margin-top: 20px;
    padding-right: 43px
}

@media (max-width:767px) {
    .footer-block .footer-icons ul li {
        margin-top: 0
    }
}

@media (max-width:992px) {
    .footer-block .footer-icons ul li {
        padding-right: 25px
    }
}

.footer-block .footer-icons ul li a {
    height: auto;
    width: 34.8px
}

.footer-block .footer-icons ul li a,
.footer-block .footer-icons ul li a:hover {
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

.footer-block .footer-icons ul li a:hover {
    opacity: .5
}

.footer-block .footer-icons ul li a img {
    height: 29px
}

.footer-block .footer-list {
    margin-top: 64px
}

@media (max-width:992px) {
    .footer-block .footer-list {
        margin-top: 33px
    }
}

@media (max-width:767px) {
    .footer-block .footer-list {
        margin-top: 36px
    }
}

.footer-block .footer-list ul {
    margin: 0;
    padding: 0
}

.footer-block .footer-list ul li {
    list-style: none;
    margin-bottom: 22px;
    text-decoration: none
}

@media (max-width:767px) {
    .footer-block .footer-list ul li {
        margin-bottom: 0;
        margin-top: 20px
    }
}

.footer-block .footer-list ul li a {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: .38px;
    line-height: 36px;
    text-decoration: none;
    text-transform: uppercase
}

.footer-block .footer-list ul li a,
.footer-block .footer-list ul li a:hover {
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

.footer-block .footer-list ul li a:hover {
    color: #29cc53
}

@media (max-width:992px) {
    .footer-block .footer-list ul li a {
        font-size: 14px;
        letter-spacing: .34px
    }
}

.footer-block .footer-container {
    margin-top: 64px
}

@media (max-width:992px) {
    .footer-block .footer-container {
        margin-top: 33px
    }
}

@media (max-width:767px) {
    .footer-block .footer-container {
        margin-top: 45px
    }
}

@media screen and (max-width:436px) {
    .footer-block .footer-container {
        margin-top: 30px
    }
}

.footer-block .footer-container p {
    color: #fff;
    margin-bottom: 40px;
    max-width: 225px
}

@media (max-width:767px) {
    .footer-block .footer-container p {
        font-size: 16px;
        letter-spacing: .19px;
        line-height: 28px;
        margin-bottom: 20px
    }
}

.footer-block .footer-container p strong {
    color: #fff;
    display: block;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: -.22px;
    line-height: 2
}

@media (max-width:767px) {
    .footer-block .footer-container p strong {
        font-size: 16px;
        letter-spacing: .19px
    }
}

.footer-block .footer-container .prime_button {
    color: #fff
}

@media (min-width:1200px) {
    .footer-block .footer-container .prime_button {
        margin-left: -35px !important
    }
}

@media (max-width:1400px) {
    .footer-block .footer-container .prime_button {
        margin-left: 12px;
        min-width: -webkit-fit-content;
        min-width: -moz-fit-content;
        min-width: fit-content
    }
}

@media (max-width:1200px) {
    .footer-block .footer-container .prime_button {
        font-size: 14px;
        min-width: 140px;
        padding: 14px 21px
    }
}

@media (max-width:992px) {
    .footer-block .footer-container .prime_button {
        font-size: 11px;
        padding: 18px 21px 19px
    }
}

@media (max-width:767px) {
    .footer-block .footer-container .prime_button {
        font-size: 16px;
        min-width: 213px
    }
}

.footer-block .footer-container .prime_button:before {
    border-color: #109296
}

.footer-block .footer-container .prime_button:hover {
    color: #fff
}

.footer-block .footer-container .prime_button:hover:before {
    background-color: #109296;
    border-color: #109296
}

.footer-block .bg-content img.bg-image {
    bottom: 0;
    position: absolute;
    right: 0;
    z-index: 0
}

@media (max-width:767px) {
    .footer-block .bg-content {
        display: none
    }
}

.footer-block .cstm-index {
    position: relative;
    z-index: 1
}

@media (max-width:767px) {


    .footer-block .col-md-3:nth-child(2),
    .footer-block .col-md-3:nth-child(3) {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .footer-block .col-md-3:nth-child(2) {
        padding-right: 2px
    }

    .footer-block .col-md-3:nth-child(3) {
        padding-left: 2px;
        text-align: right
    }
}

@media (max-width:767px) {
    .bg-content img {
        display: none
    }
}

.footer-block .bg-content img.bg-image {
    bottom: 0;
    position: absolute;
    right: 0;
    z-index: 0
}

@media (max-width:767px) {
    .footer-block .bg-content {
        display: none
    }
}

.img-fluid {
    height: auto;
    max-width: 100%
}

.footer-block .cstm-index {
    position: relative;
    z-index: 1
}

.footer-block .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: calc(var(--bs-gutter-x)*-.5);
    margin-right: calc(var(--bs-gutter-x)*-.5);
    margin-top: calc(var(--bs-gutter-y)*-1)
}

.my-custom-row>* {
    -ms-flex-negative: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    flex-shrink: 0;
    margin-top: var(--bs-gutter-y);
    max-width: 100%;
    padding-left: calc(var(--bs-gutter-x)*.5);
    padding-right: calc(var(--bs-gutter-x)*.5);
    width: 100%
}

/* .footer-block .col-md-3 {
    width: 25%
} */

.footer-block .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.footer-block .logo-content p {
    color: #fff;
    font-size: 16px;
    letter-spacing: .13px;
    line-height: 1.5;
    margin-bottom: 25px
}

@media (max-width:767px) {
    .footer-block .logo-content p {
        margin-bottom: 27px
    }
}

.footer-block .logo-content p span {
    display: block
}

.footer-block .logo-content p a {
    color: #fff;
    text-decoration: none
}

.footer-block .logo-content p a:hover {
    color: #29cc53
}

@media (min-width: 768px) {
    .footer-block .col-md-3 {
        width: 25%;
    }
}

@media (max-width: 1200px) {
    header .navbar-dark .navbar-nav.right-nav {
        margin-left: 20px;
    }
}

@media (max-width: 1400px) {
    header .navbar-dark .navbar-nav.right-nav {
        margin-left: 65px;
    }
}

@media (max-width: 1130px) {
    header .navbar-dark .navbar-nav {
        margin-left: 100px
    }
}

@media (max-width: 1030px) {
    header .navbar-dark .navbar-nav {
        margin-left: 80px
    }
}

@media (min-width:992px) {
    header .navbar-dark .navbar-nav.right-nav li.skew-dropdown .sub-menu {
        background-color: #049296;
        border-radius: 0 0 20px 0;
        left: -110px;
        padding: 30px 0 20px 30px;
        -webkit-transform: skewX(-24deg);
        -ms-transform: skewX(-24deg);
        transform: skewX(-24deg)
    }
}

@media (min-width: 992px) {
    header .navbar-dark .navbar-nav li:hover .sub-menu {
        display: block;
    }
}

@media (min-width:992px) {
    header .navbar-dark .navbar-nav li .sub-menu {
        background-color: #313d4d;
        border-bottom: 5px solid #109296
    }
}

header .navbar-dark .navbar-nav li .sub-menu {
    display: none;
    left: 0;
    list-style: none;
    margin: 1px auto 0;
    min-width: 230px;
    padding: 0;
    position: absolute;
    top: 100%
}

@media (max-width:1200px) {
    header .navbar-dark .navbar-nav.right-nav li.skew-dropdown .sub-menu {
        left: -70px;
    }
}